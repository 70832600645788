<!-- <Head> -->
<!-- Global site tag (gtag.js) - Google Ads: 471711162 -->
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=AW-471711162"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'AW-471711162');

</script>
</Head> -->
<template>
  <Head>
    <title v-if="content && content.title !== null">{{ content.title }}</title>
    <title v-else>
      Best Online Coaching for Neet, IIT-JEE, CBSE,CA and Junior IAS Initiative
      - VB Live
    </title>
    <meta
      v-if="content && content.meta_description !== null"
      name="description"
      :content="content.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="content && content.meta_keywords !== null"
      name="keywords"
      :content="content.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <div class="listing">
    <div class="main-list-wrapper">
      <div class="enquire-banner">
        <div class="container-fluid">
          <img
            v-if="content && content.banner_image != null"
            :src="content.banner_image"
            class="d-block layer-img"
            alt="..."
          />
          <img
            v-else
            src="../assets/Banner.jpg"
            class="d-block layer-img"
            alt="..."
          />
        </div>
      </div>
      <div class="filter-header">
        <div ref="events_head" class="container">
          <ul class="list-unstyled filter-list">
            <li v-if="content">
              <!-- <input
                type="text"
                class="form-control select-button search-filter"
                v-model="content.title"
                @keyup="resetEventsFetch"
                placeholder="Search by name"
              /> -->
              <h5>
                {{ content.title }}
              </h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="detail">
    <div class="container career-form-wrapper">
      <h6>
        Please fill in the below details to know more about our online courses
      </h6>
      <form class="row" @submit.prevent="saveCounselling">
        <div class="col-md-6">
          <label class="form-label">Parent Name <span>*</span></label>
          <input
            type="text"
            placeholder="Parent Name"
            class="form-control"
            v-model="your_name"
          />
          <div v-if="errors && errors.your_name" class="error-msg">
            {{ errors.your_name[0] }}
          </div>
        </div>

        <div class="col-md-6">
          <label class="form-label">Email ID <span>*</span></label>
          <input
            type="email"
            placeholder="Email ID"
            class="form-control"
            v-model="email"
          />
          <div v-if="errors && errors.email" class="error-msg">
            {{ errors.email[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Mobile Number <span>*</span></label>
          <input
            type="text"
            placeholder="Mobile Number"
            class="form-control"
            v-model="phone"
          />
          <div v-if="errors && errors.phone_number" class="error-msg">
            {{ errors.phone_number[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Inquiring For <span>*</span></label>
          <Multiselect
            v-model="inquiringForOptions.value"
            v-bind="inquiringForOptions"
          />
          <div v-if="errors && errors.inquiring_for" class="error-msg">
            {{ errors.inquiring_for[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Student Name <span>*</span></label>
          <input
            type="text"
            placeholder="Student Name"
            class="form-control"
            v-model="name"
          />
          <div v-if="errors && errors.name" class="error-msg">
            {{ errors.name[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Select Standard <span>*</span></label>
          <Multiselect
            v-model="standardOptions.value"
            v-bind="standardOptions"
          />
          <div v-if="errors && errors.standard_id" class="error-msg">
            {{ errors.standard_id[0] }}
          </div>
        </div>
        <div class="col-md-12">
          <label class="form-label">Got any questions for us?</label>
          <textarea
            class="form-control"
            v-model="questions"
            rows="3"
            placeholder="Enter questions"
          >
          </textarea>
        </div>
        <div class="col-md-12 text-center">
          <button
            type="submit"
            :disabled="isSubmitting == true"
            class="btn cta-primary"
          >
            Schedule a call back
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import CommonService from "@/services/CommonService";
import Multiselect from "@vueform/multiselect";
import { Head } from "@vueuse/head";

export default {
  name: "CareerCounselling",
  components: {
    Head,
    Multiselect,
  },
  data() {
    return {
      content: null,
      your_name: "",
      name: "",
      email: "",
      phone: "",
      questions: "",
      errors: {},
      isSubmitting: false,
      inquiringForOptions: {
        options: [
          {
            value: "Myself",
            label: "Myself",
          },
          {
            value: "Son",
            label: "Son",
          },
          {
            value: "Daughter",
            label: "Daughter",
          },
          {
            value: "Brother",
            label: "Brother",
          },
          {
            value: "Sister",
            label: "Sister",
          },
          {
            value: "Relative",
            label: "Relative",
          },
          {
            value: "Friend",
            label: "Friend",
          },
        ],
        placeholder: "Inquiring For",
        canDeselect: false,
        searchable: true,
      },
      standardOptions: {
        value: null,
        options: [],
        placeholder: "Select Standard",
        canDeselect: false,
        loading: true,
        searchable: true,
      },
    };
  },
  mounted() {
    this.loadStandards();
    this.fetchContent();
  },
  methods: {
    fetchContent() {
      let params = {
        slug: "enquire",
      };
      CommonService.getContentPage(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.content = response.data.content;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadStandards() {
      CommonService.getFeaturedStandards()
        .then((response) => {
          this.standardOptions.loading = false;
          if (response.data.status === "SUCCESS") {
            this.standardOptions.options = []; // to clear previously loaded options
            let standards = response.data.standards;
            if (standards.length > 0) {
              standards.map((standard) => {
                let options = {
                  value: standard.id,
                  label: standard.standard_title,
                };
                this.standardOptions.options.push(options);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveCounselling() {
      const params = {
        your_name: this.your_name,
        name: this.name,
        email: this.email,
        phone_number: this.phone,
        inquiring_for: this.inquiringForOptions.value,
        standard_id: this.standardOptions.value,
        questions: this.questions,
        type: "ENQUIRE",
      };
      this.isSubmitting = true;
      CommonService.saveCounselling(params)
        .then((response) => {
          this.isSubmitting = false;
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.standardOptions.value = null;
            this.inquiringForOptions.value = null;
            this.your_name = "";
            this.name = "";
            this.email = "";
            this.phone = "";
            this.questions = "";
            this.errors = {};
          }
          if (response.data.status === "ERROR") {
            this.errors = response.data.message || {};
          }
        })
        .catch((error) => {
          this.isSubmitting = false;
          console.error(error);
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import "@/style/content-page.scss";
@import "@/style/listing.scss";
</style>
